@tailwind base;
@tailwind components;
@tailwind utilities;

html, body{
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-prev{
  left: 25px !important;
  z-index: 1 !important;
}
.slick-next{
  right: 25px !important;
  z-index: 1 !important;
}

#partners .slick-prev{
  left: 0 !important;
}
#partners .slick-next{
  right: 0 !important;
}
#partners .slick-prev::before,
#partners .slick-next::before{
  color: #000;
}